import React, { useEffect } from 'react'

import StepItem from './step-item'

import './index.css'

const mobileSvgPaths = {
  0: `
    <svg class="svg-stroke" width="100%" height="100%" viewBox="0 0 452 594" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="path" stroke="black" d="M426.1,0.2l-0.4,0.3c12.8,15.2,26.4,43.7,25.6,79.8s-16.1,79.9-61.2,125.6c-56.3,57.1-124.2,68.2-189.7,82
      c-65.5,13.8-128.6,30.5-174.9,98.8C7,414.1-0.3,439.3,0.3,462c0.5,22.7,8.9,43,21.5,60.6c25.2,35.2,67.4,59.6,98.7,71.3l0.2-0.5
      c-31.2-11.7-73.3-36.1-98.5-71.1C9.6,504.8,1.3,484.6,0.8,462c-0.6-22.6,6.6-47.7,25.1-75c46.2-68.2,109.1-84.8,174.6-98.6
      c65.5-13.8,133.6-24.9,190-82.2c45.1-45.8,60.5-89.7,61.3-125.9C452.6,44.1,438.9,15.5,426.1,0.2z"/>
      <path class="mask" stroke="white" d="M426.1,0.2l-0.4,0.3c12.8,15.2,26.4,43.7,25.6,79.8s-16.1,79.9-61.2,125.6c-56.3,57.1-124.2,68.2-189.7,82
      c-65.5,13.8-128.6,30.5-174.9,98.8C7,414.1-0.3,439.3,0.3,462c0.5,22.7,8.9,43,21.5,60.6c25.2,35.2,67.4,59.6,98.7,71.3l0.2-0.5
      c-31.2-11.7-73.3-36.1-98.5-71.1C9.6,504.8,1.3,484.6,0.8,462c-0.6-22.6,6.6-47.7,25.1-75c46.2-68.2,109.1-84.8,174.6-98.6
      c65.5-13.8,133.6-24.9,190-82.2c45.1-45.8,60.5-89.7,61.3-125.9C452.6,44.1,438.9,15.5,426.1,0.2z"/>
    </svg>
  `,
  1: `
    <svg class="svg-stroke" width="100%" height="100%" viewBox="0 0 454 565" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="path" stroke="black" d="M138.9,0.9c-34.1,21.6-81,60.3-110.4,105.8c-29.4,45.6-41.5,98.1-6.1,147.6c44.3,61.8,110.3,62.1,179,58.8
      c68.8-3.4,140.3-10.4,196,36.2c44.5,37.3,57.6,80.8,55.8,120.1c-1.8,39.3-18.4,74.3-33.2,94.6"/>
      <path class="mask" stroke="white"  d="M138.9,0.9c-34.1,21.6-81,60.3-110.4,105.8c-29.4,45.6-41.5,98.1-6.1,147.6c44.3,61.8,110.3,62.1,179,58.8
      c68.8-3.4,140.3-10.4,196,36.2c44.5,37.3,57.6,80.8,55.8,120.1c-1.8,39.3-18.4,74.3-33.2,94.6"/>
    </svg>
  `
}

const clamp = (number: number, min: number, max: number) => {
  return Math.max(min, Math.min(number, max))
}

const MobileSteps = ({ Items, backgroundColor }: { Items: any[]; backgroundColor: string }) => {
  useEffect(() => {
    // const stepsSection: any = document.getElementById("steps-section-mobile");
    const stepsSection: any = document.getElementById('steps-section-mobile')?.parentElement?.parentElement
    const sections = document.getElementsByClassName('mobile-steps-group')
    const svgLines = document.getElementsByClassName('svg-stroke')

    console.log('STEPS SECTION', stepsSection)

    for (let j = 0; j < svgLines.length; j++) {
      const relatedPath: any = svgLines[j].getElementsByClassName('path')[0]
      const relatedPathMask: any = svgLines[j].getElementsByClassName('mask')[0]
      const len = relatedPath.getTotalLength()

      relatedPath.style.strokeDasharray = len
      relatedPath.style.strokeDashoffset = len

      relatedPathMask.style.strokeDasharray = 10
      relatedPathMask.style.strokeWidth = 5
      relatedPathMask.style.stroke = backgroundColor
    }

    const drawLines = () => {
      // console.log('drawing lines...');
      const windowBottom = document.documentElement.scrollTop + document.documentElement.clientHeight

      for (let i = 0; i < sections.length; i++) {
        const section: any = sections[i]
        const secTopPointInDoc = stepsSection.offsetTop + section.offsetTop
        // const secTopPointInDoc = stepsSection.offsetTop;

        console.log(`SECTION RATIO - SECTION ${i}`, windowBottom)
        console.log(`SECTION RATIO - SECTION ${i}`, secTopPointInDoc)
        console.log(`SECTION RATIO - SECTION ${i}`, windowBottom - secTopPointInDoc)
        console.log(`SECTION RATIO - SECTION ${i}`, section.offsetHeight)

        // console.log(`SECTION RATIO - SECTION ${i}`, document.documentElement.scrollTop);
        const ratio = clamp((windowBottom - secTopPointInDoc) / section.offsetHeight, 0, 1)
        // const ratio = clamp(
        //   (windowBottom - secTopPointInDoc) / stepsSection.offsetHeight,
        //   0,
        //   1
        // );
        console.log(`SECTION RATIO - SECTION RATIO ${i}`, ratio * ratio)

        const relatedSvg = svgLines[i]
        const relatedPath: any = relatedSvg?.getElementsByClassName('path')?.[0]
        const pathLength = relatedPath?.getTotalLength()
        const drawLength = pathLength * ratio * ratio
        // console.log('DRAWLENGTH', drawLength);
        if (relatedPath) {
          relatedPath.style.strokeDashoffset = pathLength - drawLength
        }
        console.log('-----------------------------------------------------------------')
      }
    }

    window.addEventListener('scroll', drawLines)

    return () => {
      console.log('unregister scroll for mobile')
      window.removeEventListener('scroll', drawLines)
    }
  }, [])

  const getMobileSvgPath = (index: number) => {
    const isEven = (index + 1) % 2 === 0

    if (isEven) {
      return (
        <div
          className="mobile-svg-size absolute -top-40 -left-12"
          dangerouslySetInnerHTML={{ __html: mobileSvgPaths[0] }}
        ></div>
      )
    }

    return (
      <div
        className="mobile-svg-size absolute top-40 -left-16"
        dangerouslySetInnerHTML={{ __html: mobileSvgPaths[1] }}
      ></div>
    )
  }

  const renderMobileStepItems = () => {
    const chunkSize = 2
    const chunckedArr = []
    for (let i = 0; i < Items.length; i += chunkSize) {
      const chunk = Items.slice(i, i + chunkSize)
      chunckedArr.push(chunk)
      // console.log(chunckedArr)
    }

    return chunckedArr.map((chunk, i) => {
      return (
        <div className={`mobile-steps-group relative mx-auto h-fit w-8/12 pb-10`} key={i}>
          {getMobileSvgPath(i)}
          {chunk.map((stepItem, i) => {
            return (
              <div className="w-full" key={`${stepItem.prominentText}-${i}`}>
                <StepItem
                  cloudinaryImage={stepItem.cloudinaryImage}
                  imgAltText={stepItem.imgAltText}
                  prominentText={stepItem.prominentText}
                  prominentTextColor={stepItem.prominentTextColor}
                  descriptionText={stepItem.descriptionText}
                  descriptionTextColor={stepItem.descriptionTextColor}
                  key={`${stepItem.prominentText}-${i}`}
                  isMobile={true}
                />
              </div>
            )
          })}
        </div>
      )
    })
  }

  return <>{renderMobileStepItems()}</>
}

export default MobileSteps
